import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { State, Store } from './types';

export const useAppSelector: TypedUseSelectorHook<State> = useSelector;

// Action types
enum COOKIE_NOTICE_ACTIONS {
    TOGGLE_PERFORMANCE_ACCEPTED = 'TOGGLE_PERFORMANCE_ACCEPTED',
    TOGGLE_ADVERTISING_ACCEPTED = 'TOGGLE_ADVERTISING_ACCEPTED',
    UPDATE_COOKIES = 'UPDATE_COOKIES',
}

// Action creators
type TogglePerformanceAcceptedAction = { type: COOKIE_NOTICE_ACTIONS.TOGGLE_PERFORMANCE_ACCEPTED };
const togglePerformanceAccepted = (): TogglePerformanceAcceptedAction => ({
    type: COOKIE_NOTICE_ACTIONS.TOGGLE_PERFORMANCE_ACCEPTED,
});

type ToggleAdvertisingAcceptedAction = { type: COOKIE_NOTICE_ACTIONS.TOGGLE_ADVERTISING_ACCEPTED };
const toggleAdvertisingAccepted = (): ToggleAdvertisingAcceptedAction => ({
    type: COOKIE_NOTICE_ACTIONS.TOGGLE_ADVERTISING_ACCEPTED,
});

type UpdateCookiesAction = {
    type: COOKIE_NOTICE_ACTIONS.UPDATE_COOKIES;
    performance: boolean;
    advertising: boolean;
};
const updateCookies = (performance: boolean, advertising: boolean): UpdateCookiesAction => ({
    type: COOKIE_NOTICE_ACTIONS.UPDATE_COOKIES,
    performance,
    advertising,
});

type Actions =
    | TogglePerformanceAcceptedAction
    | ToggleAdvertisingAcceptedAction
    | UpdateCookiesAction;

export { togglePerformanceAccepted, toggleAdvertisingAccepted, updateCookies };

// Default state
const initialState: Store = {
    asModal: true,
    performanceAccepted: false,
    advertisingAccepted: false,
};

// Reducer
const store = (state: Store, action: Actions): Store => {
    switch (action.type) {
        case COOKIE_NOTICE_ACTIONS.TOGGLE_PERFORMANCE_ACCEPTED:
            return { ...state, performanceAccepted: !state.performanceAccepted };
        case COOKIE_NOTICE_ACTIONS.TOGGLE_ADVERTISING_ACCEPTED:
            return { ...state, advertisingAccepted: !state.advertisingAccepted };
        case COOKIE_NOTICE_ACTIONS.UPDATE_COOKIES:
            return {
                ...state,
                performanceAccepted: action.performance,
                advertisingAccepted: action.advertising,
            };
        default:
            return { ...initialState, ...state };
    }
};

export default store;
