import React from 'react';
import Cookies from 'js-cookie';
import { useDispatch } from 'react-redux';
import { useAppSelector } from './store';
import * as actions from './store';
import ModalView from './ModalView';
import AsideView from './AsideView';

export const COOKIE_SLOTS = {
    ACCEPTED: 'acceptCookies',
    TIMESTAMP: 'acceptCookiePolicyDate',
    PERFORMANCE: 'acceptCookiePolicy-performance',
    ADVERTISING: 'acceptCookiePolicy-marketing',
};

const cookieSettings = { expires: 365 };

const App = (): JSX.Element | null => {
    // Selectors & hooks
    const dispatch = useDispatch();
    const asModal = useAppSelector(({ store }) => store.asModal);
    const performanceAccepted = useAppSelector(({ store }) => store.performanceAccepted);
    const advertisingAccepted = useAppSelector(({ store }) => store.advertisingAccepted);
    const cookiePageUrl = useAppSelector(({ store }) => store.cookiePageUrl);
    // No point going further if they've already dismissed the modal
    if (asModal && Cookies.get(COOKIE_SLOTS.ACCEPTED)) return null;
    // Do the 2 checkboxes differ from what's in the cookies?
    const performanceChanged =
        performanceAccepted !== (Cookies.get(COOKIE_SLOTS.PERFORMANCE) === 'true');
    const advertisingChanged =
        advertisingAccepted !== (Cookies.get(COOKIE_SLOTS.ADVERTISING) === 'true');
    let hasUnsavedChanges = false; // I know none of this is logical -> cfr.: DAI001-5154
    // If something changed, render "save changes" rather than "accept all cookies"... (ok, makes sense)
    if (performanceChanged || advertisingChanged) hasUnsavedChanges = true;
    // ... if both are already accepted -> always render "save changes"... for some reason
    else if (performanceAccepted && advertisingAccepted) hasUnsavedChanges = true;
    // ... except when both are deselected -> always render "accept all cookies"
    if (!performanceAccepted && !advertisingAccepted) hasUnsavedChanges = false;

    const togglePerformance = (): void => {
        dispatch(actions.togglePerformanceAccepted());
    };

    const toggleAdvertising = (): void => {
        dispatch(actions.toggleAdvertisingAccepted());
    };

    const setCookies = (performance: boolean, advertising: boolean): void => {
        Cookies.set(COOKIE_SLOTS.ACCEPTED, `${true}`, cookieSettings);
        Cookies.set(COOKIE_SLOTS.PERFORMANCE, `${performance}`, cookieSettings);
        Cookies.set(COOKIE_SLOTS.ADVERTISING, `${advertising}`, cookieSettings);
        Cookies.set(COOKIE_SLOTS.TIMESTAMP, Date.now().toString(), cookieSettings);
        // Update state
        dispatch(actions.updateCookies(performance, advertising));
        // Reload page (backend needs to enable/disable tracking)
        window.location.reload();
    };

    const saveChanges = (): void => {
        setCookies(performanceAccepted, advertisingAccepted);
    };

    const acceptAllCookies = (): void => {
        setCookies(true, true);
    };

    const rejectAllCookies = (): void => {
        setCookies(false, false);
    };

    if (asModal) {
        return (
            <ModalView
                performanceAccepted={performanceAccepted}
                advertisingAccepted={advertisingAccepted}
                cookiePageUrl={cookiePageUrl}
                hasUnsavedChanges={hasUnsavedChanges}
                togglePerformance={togglePerformance}
                toggleAdvertising={toggleAdvertising}
                rejectAllCookies={rejectAllCookies}
                acceptAllCookies={acceptAllCookies}
                saveChanges={saveChanges}
            />
        );
    } else {
        return (
            <AsideView
                performanceAccepted={performanceAccepted}
                advertisingAccepted={advertisingAccepted}
                hasUnsavedChanges={hasUnsavedChanges}
                togglePerformance={togglePerformance}
                toggleAdvertising={toggleAdvertising}
                rejectAllCookies={rejectAllCookies}
                acceptAllCookies={acceptAllCookies}
                saveChanges={saveChanges}
            />
        );
    }
};

export default App;
