import React, { useEffect } from 'react';
import { CrossX } from '../icons';
import * as Style from './Modal.styles';

export const ModalSizes = {
    narrow: 'narrow',
    wide: 'wide',
};

export const Modal = ({ size = "", children }) => {
    useEffect(() => {
        document.body.classList.add('modal-open');
        return () => document.body.classList.remove('modal-open');
    }, []);
    return (
        <Style.Modal>
            <Style.ModalDialog size={size} className={size}>
                <Style.ModalContent>
                    {children}
                </Style.ModalContent>
            </Style.ModalDialog>
        </Style.Modal>
    );
};

export const ModalHeader = ({ children, closeHandler }) => (
    <Style.ModalHeader>
        <Style.ModalTitle>
            {children}
        </Style.ModalTitle>
        {closeHandler && (
            <Style.CloseButton onClick={closeHandler}>
                <CrossX />
            </Style.CloseButton>
        )}
    </Style.ModalHeader>
);

export const ModalBody = Style.ModalBody; // eslint-disable-line prefer-destructuring

export const ModalFooter = ({ children }) => (
    <Style.ModalFooter className="modal-footer">
        <div className='button-group text-right'>
            {children}
        </div>
    </Style.ModalFooter>
);

export const ModalBackDrop = () => <Style.ModalBackDrop />;
