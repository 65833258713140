import styled from 'styled-components';

const defaultButtonStyles = `
    display: inline-block;
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 1.5rem;
    line-height: 20px;
    text-align: center;
    text-decoration: none;
    text-transform: none;
    white-space: nowrap;
    cursor: pointer;
    margin: 0;
    padding: 1rem 1.5rem;
    overflow: visible;
    vertical-align: middle;
    touch-action: manipulation;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
`;

const defaultDisabledStyles = `
    cursor: default;
    pointer-events: none;
    opacity: .65;
    box-shadow: none;
    display: inline-block;
    color: #A1B6BD;
    background-image: none;
    border-width: 1px;
    border-radius: 2px;
`;

export const PrimaryButton = styled.button`
    ${defaultButtonStyles}
    ${props => props.pullRight ? `margin-left: 20px !important;` : ''}
    color: #fff;
    border: 1px solid #09C;
    border-radius: .2rem;
    background-color: #09C;
    -webkit-transition: color 0.2s ease-in-out,background-color 0.2s ease-in-out,border-color 0.2s ease-in-out;
    transition: color 0.2s ease-in-out,background-color 0.2s ease-in-out,border-color 0.2s ease-in-out;
    &:hover {
        background-color: #007399;
        border-color: #006b8f;
    }
    &:focus {
        background-color: #007399;
        border-color: #006b8f;
        outline: 0;
    }
    &:active {
        background-color: #007399;
        border-color: #006b8f;
        box-shadow: inset 0 3px 5px rgba(0,0,0,0.125);
    }

    ${props => props.disabled ? `
        background-color: #F1F1F1;
        border-color: #F1F1F1;
        ${defaultDisabledStyles}
    ` : ''}
`;

export const SecondaryButton = styled.button`
    ${defaultButtonStyles}
    ${props => props.pullRight ? `margin-left: 20px !important;` : ''}
    color: #6399AE;
    border: 1px solid #C4CFD5;
    border-radius: 2px;
    background-color: #fff;
    -webkit-transition: color 0.2s ease-in-out,background-color 0.2s ease-in-out,border-color 0.2s ease-in-out;
    transition: color 0.2s ease-in-out,background-color 0.2s ease-in-out,border-color 0.2s ease-in-out;

    &:active {
        color: #fff;
        background-color: #09C;
        background-image: none;
        text-decoration: none;
        border-color: #C4CFD5;
        box-shadow: inset 0 3px 5px rgba(0,0,0,0.125);
    }

    &:hover {
        text-decoration: none;
        color: #fff;
        background-color: #09C;
        border-color: #C4CFD5;
    }

    &:focus {
        text-decoration: none;
        color: #fff;
        background-color: #09C;
        border-color: #C4CFD5;
        outline-offset: -2px;
        outline: 0;
    }

    ${props => props.disabled ? `
        background-color: transparent;
        border-color: #C4CFD5;
        ${defaultDisabledStyles}
    ` : ''}
`;

export const TextualButton = styled.button`
    ${defaultButtonStyles}
    ${props => props.pullRight ? `margin-left: 20px !important;` : ''}
    color: #0099CC;
    text-decoration: none;
    font-weight: 400;
    background-color: transparent;
    border: 1px solid transparent;
    outline: none;
    &:hover, &:focus, &:active {
        text-decoration: underline;
    }
`;
