import i18n from '@common/i18n';
import React from 'react';

type PropsType = {
    labelKey: string;
    hyperlinks: Record<string, string | undefined>;
};

export const Linkify = ({ labelKey, hyperlinks }: PropsType): JSX.Element => {
    return (
        <>
            {i18n(labelKey)
                .split(/{|}/g)
                .filter((part: string) => !!part)
                .map((part: string) => {
                    const key = Object.keys(hyperlinks).find((k) => k === part);
                    if (!key) return part;
                    return (
                        <a
                            key={key}
                            href={hyperlinks[key]}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {i18n(key)}
                        </a>
                    );
                })}
        </>
    );
};

export default Linkify;
