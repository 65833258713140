import boiler from '@boiler';
import i18n from '@common/i18n';
import Cookies from 'js-cookie';
import App, { COOKIE_SLOTS } from './App';
import store from './store';

const defaultLabels = {
    get_a_better_experience: 'Get a better experience thanks to cookies',
    essential_and_functional: 'Essential and functional cookies:',
    essential_and_functional_info: 'These are necessary, respectively, to allow navigation on our website and provide the services you will request ("minimal cookies").',
    additional_cookies: 'Additional cookies',
    performance: 'Performance',
    performance_info: 'cookies collecting statistics about traffic and users\' behavior on our or third party websites',
    advertising: 'Advertising/targeting',
    advertising_info: 'cookies used to deliver adverts on our or third party websites more relevant to you and your interests, as well as to measure the effectiveness of advertising campaigns',
    reject_all_cookies: 'Reject all cookies',
    accept_all_cookies: 'Accept all cookies',
    accept_changes: 'Save changes',
    to_learn_more: 'To learn more about our cookies, visit our {cookie_notice}.',
    cookie_notice: 'Cookie Notice',
};

const mapInputsToState = (json) => {
    // Pass labels to i18n
    i18n.set({ ...defaultLabels, ...json.labels});

    return {
        store: {
            ...json.store,
            performanceAccepted: (Cookies.get(COOKIE_SLOTS.PERFORMANCE) === 'true') || false,
            advertisingAccepted: (Cookies.get(COOKIE_SLOTS.ADVERTISING) === 'true') || false,
        },
    };
};

// eslint-disable-next-line func-names
(function () {
    boiler.initAppInstances(
        App,
        'cookie-notice',
        { store },
        mapInputsToState,
    );
}());
