import styled from 'styled-components';

const font = `
    font-family: "Open Sans", sans-serif;
    color: #585858;
    font-weight: 200;
    font-size: 1.6rem;
    line-height: 1.5;
`;

export const AsideViewStyleContainer = styled.div`
    background-color: #f3f8fa;
    border: 1px solid #d4e1e6;
    border-radius: 0.5rem;
    padding: 2rem 1.5rem;
    margin-bottom: 1rem;
    ${font}

    h2 {
        ${font}
        color: #0EB6E7;
        font-weight: 600;
        font-size: 1.8rem;
        line-height: 2rem;
        margin-bottom: 0;
    }

    hr {
        margin: 1rem 0;
    }

    strong {
        font-weight: 400;
    }

    button {
        width: 100%;
        font-family: inherit;
        &:first-of-type {
            margin-bottom: 5px;
        }
    }

    .cookie-consent {
        ${font}
        label {
            ${font}
            display: block;
            margin-bottom: 1rem;
        }
        input {
            margin-right: 0.75rem;
        }
        a {
            font-weight: 400;
        }
        .form-group {
            margin-bottom: 3rem;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
`;

export const ModalViewStyleContainer = styled.div`
    .cookie-consent {
        font-weight: normal;
        line-height: 2rem;
        label {
            margin-bottom: 1rem;
            line-height: 2rem;
            display: block;
            font-family: inherit;
        }

        label,
        span {
            font-weight: normal;
        }

        input {
            margin-right: 0.75rem;
        }
        a {
            font-weight: 400;
        }
        .form-group {
            margin-bottom: 3rem;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
`;
