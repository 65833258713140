import React from 'react';

export * as Medium from './medium';
export * as Illustration from './illustration';
export * as Large from './large';
export * as ExtraLarge from './extralarge';
export * as Glyph from './glyph';
export * as Rsn from './rsn';

const Svg = ({ width, height, transform, children }) => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        width={width}
        height={height}
        transform={transform || null}
        viewBox={`0 0 ${width} ${height}`}
    >
        {children}
    </svg>
);

export const InfoIcon = () => (
    <Svg width={20} height={20}>
        <circle cx='50%' cy='50%' r='10' fill='#09C' />
        <g stroke='#FFF' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' transform='translate(8 4)'>
            <line x1='2.25' x2='2.25' y1='4.595' y2='10.569' />
            <line x1='2.25' x2='2.25' y1='1.034' y2='1.482' />
            <line x1='.385' x2='4.114' y1='10.569' y2='10.569' />
            <line x1='.385' x2='2.25' y1='4.559' y2='4.559' />
        </g>
    </Svg>
);

export const MagnifyingGlass = () => (
    <Svg width={20} height={20}>
        <g stroke='#09C' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' transform='translate(3 2)'>
            <path fill='transparent' d='M11,6.12565 C11,9.12220043 8.5708173,11.5525 5.57315,11.5525 C2.57659957,11.5525 0.1463,9.12220043 0.1463,6.12565 C0.1463,3.12909957 2.57659957,0.6988 5.57315,0.6988 C8.5708173,0.6988 11,3.12909957 11,6.12565 L11,6.12565 Z' />
            <line x1='10.123' x2='14.041' y1='10.873' y2='14.791' />
        </g>
    </Svg>
);

export const DownArrow = () => (
    <Svg width={20} height={20}>
        <polyline
            fill='#fff'
            stroke='#09C'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='2'
            points='5.188 8 9.999 12.417 14.813 8'
        />
    </Svg>
);

export const UpArrow = () => (
    <Svg width={20} height={20} transform='rotate(-180)'>
        <polyline
            fill='#fff'
            stroke='#09C'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='2'
            points='5.188 8 9.999 12.417 14.813 8'
        />
    </Svg>
);

export const CrossX = ({ color }) => (
    <Svg width={20} height={20}>
        <g
            stroke={color || '#09C'}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='2'
            transform='rotate(-45 13.536 1.464)'
        >
            <line x1='.5' x2='9.5' y1='5' y2='5' />
            <line x1='5' x2='5' y1='9.5' y2='.5' />
        </g>
    </Svg>
);

export const CascadingIcon = () => (
    <Svg width={30} height={30}>
        <path
            fill='#09C'
            d='M18.0086102,2 C19.0565307,2 19.9174719,2.8063056 20.0018307,3.83439055 L20.0085354,3.98270542 L20.034,7 L23.0086102,7 C24.0565307,7 24.9174719,7.8063056 25.0018307,8.83439055 L25.0085354,8.98270542 L25.034,12 L28,12 C29.1045695,12 30,12.8954305 30,14 L30,26 C30,27.1045695 29.1045695,28 28,28 L12,28 C10.8954305,28 10,27.1045695 10,26 L9.999,23 L7,23 C5.9456382,23 5.08183488,22.1841222 5.00548574,21.1492623 L5,21 L5,18 L2,18 C0.945638205,18 0.0818348782,17.1841222 0.00548573647,16.1492623 L3.38218342e-11,16 L3.38218342e-11,4 C3.38218342e-11,2.9456382 0.815877791,2.08183488 1.85073766,2.00548574 L2,2 L18.0086102,2 Z M28,14 L12,14 L12,26 L28,26 L28,14 Z M18,16 C20.209139,16 22,17.790861 22,20 C22,22.209139 20.209139,24 18,24 C15.790861,24 14,22.209139 14,20 C14,17.790861 15.790861,16 18,16 Z M18,18 C16.8954305,18 16,18.8954305 16,20 C16,21.1045695 16.8954305,22 18,22 C19.1045695,22 20,21.1045695 20,20 C20,18.8954305 19.1045695,18 18,18 Z M23.034,12 L23.008,9 L7,9 L7,21 L9.999,21 L10,14 C10,12.8954305 10.8954305,12 12,12 L23.034,12 Z M18.008,4 L2,4 L2,16 L5,16 L5,9 C5,7.9456382 5.81587779,7.08183488 6.85073766,7.00548574 L7,7 L18.034,7 L18.008,4 Z'
        />
    </Svg>
);
