import styled, { css } from 'styled-components';

export const defaultTheme = {
    backgroundColor: "#09C",
    textColor: "#FFF",
    headerBorder: false,
};

export const invertedTheme = {
    backgroundColor: defaultTheme.textColor,
    textColor: defaultTheme.backgroundColor,
    headerBorder: true,
};

/*

NOTE:
Do NOT use rem-values in this value.
Everything must be in pixels.

The Modal-component is being used on different platforms which have different rem base-values.

*/

const font = `
    font-family: "Roboto",sans-serif;
    font-size: 16px;
    font-weight: 200;
    line-height: 15px;
    text-align: left;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
`;

export const Modal = styled.div`
    ${font}
    overflow-x: hidden;
    overflow-y: auto;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    outline: 0;
    z-index: 1050;
    box-sizing: inherit;
    -webkit-overflow-scrolling: touch;

    label,
    span {
        ${font}
    }

    ${(props) =>
        props.hasIcons &&
        css`
            i.icon {
                margin-right: 1rem;
                :last-child {
                    margin-right: 0;
                }
            }
        `}
`;

Modal.defaultProps = {
    theme: defaultTheme,
    hasIcons: false,
};

export const ModalDialog = styled.div`
    position: relative;
    width: auto;
    margin: 10px;
    @media (min-width: 576px) {
        max-width: 90%;
        margin: 30px auto;
    }
    @media (min-width: 830px) {
        max-width: 790px;
        margin: 30px auto;

        &.wide {
            max-width: 980px;
            width: 70%;
        }

        &.narrow {
            max-width: 576px;
            width: 90%;
        }
    }
`;

export const ModalContent = styled.div`
    position: relative;
    background-clip: padding-box;
    background-color: #fff;
    box-shadow: 0 3px 9px rgba(0,0,0,.5);
    border-radius: 4px;
    outline: 0;
`;

export const ModalHeader = styled.div`
    position: relative;
    padding: 18px 20px;
    background-color: ${(props) => props.theme.backgroundColor};
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-bottom: ${(props) => props.theme.headerBorder ? "1px solid #c4cfd5" : "none"};
    .close {
        position: absolute;
        top: 20px;
        right: 20px;
    }
    span {
        font-size: 18px;
        color: ${(props) => props.theme.textColor};
        font-weight: 400;
    }
`;
ModalHeader.defaultProps = { theme: defaultTheme };

export const CloseButton = styled.button`
    position: absolute;
    top: 20px;
    right: 20px;
    border: 0;
    border-radius: 5px;
    width: 20px;
    height: 20px;
    padding: 0;
    background: none;

    &:active,
    &:focus {
        background-color: rgba(255, 255, 255, 0.2);
        outline: 0;
    }

    svg g {
        stroke: ${(props) => props.theme.textColor || "white"};
    }
`;
CloseButton.defaultProps = { theme: defaultTheme };

export const ModalTitle = styled.h4`
    ${font}
    margin: 0;
    font-size: 18px;
    font-weight: 400;
    color: ${(props) => props.theme.textColor};
`;
ModalTitle.defaultProps = { theme: defaultTheme };

export const ModalBody = styled.div`
    padding: 35px 20px;
    height: auto;
`;

export const ModalFooter = styled.div`
    padding: 20px;
    border-top: 1px solid #C4CFD5;

    .btn + .btn {
        margin-bottom: 0;
        margin-left: 5px;
    }
`;

export const ModalBackDrop = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(3, 40, 61, 0.7);
    z-index: 1045;
    opacity: 1;
`;
